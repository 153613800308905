import React, { useState, useContext, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ServiceCall, apiCall, apiCall1 } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { constants } from "../utility/constants";
import { PatientpageContext } from "../context/GlobalContext";
import DoctorsAppointmentListItemNew from "./DoctorsAppointmentListItemNew";
import { useHistory, useLocation } from "react-router-dom";
import MedicationRoundedIcon from "@mui/icons-material/MedicationRounded";
import DeviceThermostatRoundedIcon from "@mui/icons-material/DeviceThermostatRounded";
import Vitalimg from "../static/health-filled-svgrepo-com.svg";
import PrescriptionShareIcon from "../static/images/prescriptionShare.png";
import { OverlayType } from "../OverlayManager/Context";
import { Grid } from "material-ui";
import { OverlayContext } from "../OverlayManager/Overlay";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PageContentLayout from "./PageContentLayout";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FormControl from "@mui/material/FormControl";
import { Box, Chip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { InputLabel } from "material-ui";
import { Typography } from "material-ui";
const GeneratePrescriptionModal = ({ onSave }) => {
  const doctorContext = useContext(PatientpageContext);
  const [selectedItem, setSelectedItem] = useState("");

  const [templateData, setTemplateData] = useState({
    print_header: false,
    print_footer: false,
    print_watermark: false,
    print_on_both_sides: false,
    font_size_px: 10,
    top_margin_cm: 1,
    bottom_margin_cm: 2,
    // Add other default values as needed
  });
  useEffect(() => {
    const fetchmargin = async () => {
      try {
        const margins = await ServiceCall.gett(
          "v2/doctor/prescription/template/defaults",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (margins) {
          setTemplateData(margins?.data);
        }
      } catch (err) {}
    };
    fetchmargin();
  }, []);

  useEffect(() => {
    // Call onSave whenever templateData changes
    onSave(templateData);
  }, [templateData, onSave]);
  const increaseTopMargin = () => {
    if (templateData.print_header === true) {
      return;
    } else if (templateData.top_margin_cm < 10) {
      setTemplateData({
        ...templateData,
        top_margin_cm: templateData.top_margin_cm + 1,
      });
    }
  };

  const decreaseTopMargin = () => {
    if (templateData.print_header === true) {
      return;
    } else if (templateData.top_margin_cm > 1) {
      setTemplateData({
        ...templateData,
        top_margin_cm: templateData.top_margin_cm - 1,
      });
    }
  };

  const increaseBottomMargin = () => {
    if (templateData.print_footer === true) {
      return;
    } else if (templateData.bottom_margin_cm < 6) {
      setTemplateData({
        ...templateData,
        bottom_margin_cm: templateData.bottom_margin_cm + 1,
      });
    }
  };

  const decreaseBottomMargin = () => {
    if (templateData.print_footer === true) {
      return;
    } else if (templateData.bottom_margin_cm > 2) {
      setTemplateData({
        ...templateData,
        bottom_margin_cm: templateData.bottom_margin_cm - 1,
      });
    }
  };

  const handleCellClick = (value) => {
    let fontSize = null;

    switch (value) {
      case "Small":
        fontSize = 8;
        break;
      case "Medium":
        fontSize = 10;
        break;
      case "Large":
        fontSize = 12;
        break;
      case "Extra Large":
        fontSize = 14;
        break;
      default:
        fontSize = null;
    }

    setTemplateData({
      ...templateData,
      font_size_px: fontSize, // Update font_size_px in the templateData
    });
  };

  return (
    <div className="prescripitionLoading">
      <h3 style={{ fontSize: "18px" }}>Print Preferences</h3>
      <div className="forms">
        <FormGroup column>
          <FormControlLabel
            required
            control={<Switch checked={templateData.print_header} />}
            label="Digital Header"
            value={templateData.print_header}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                print_header: !templateData.print_header,
              });
            }}
          />
          <FormControlLabel
            required
            control={<Switch checked={templateData.print_footer} />}
            label="Digital Footer"
            value={templateData.print_footer}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                print_footer: !templateData.print_footer,
              });
            }}
          />
          <FormControlLabel
            required
            control={<Switch checked={templateData.print_watermark} />}
            label="Watermark"
            value={templateData.print_watermark}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                print_watermark: !templateData.print_watermark,
              });
            }}
          />
          <FormControlLabel
            required
            control={<Switch checked={templateData.print_on_both_sides} />}
            label="Print on both sides"
            value={templateData.print_on_both_sides}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                print_on_both_sides: !templateData.print_on_both_sides,
              });
            }}
          />
        </FormGroup>
      </div>

      <div style={{ margin: "auto", paddingLeft: "10px", fontSize: "14px" }}>
        <p style={{ fontSize: "16px", marginLeft: "-21px" }}>Font Size</p>
        <div
          style={{
            display: "flex",
            border: "1px solid #c0b0b0",
            padding: "3px",
            fontSize: "16px",
            borderRadius: "5px",
          }}
        >
          <div
            onClick={() => handleCellClick("Small")}
            style={{
              cursor: "pointer",
              marginRight: "5px",
              backgroundColor:
                templateData.font_size_px === 8 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 8 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Small
          </div>
          <div style={{ color: "#c0b0b0" }}>|</div>
          <div
            onClick={() => handleCellClick("Medium")}
            style={{
              cursor: "pointer",
              marginRight: "5px",
              backgroundColor:
                templateData.font_size_px === 10 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 10 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Medium
          </div>
          <div style={{ color: "#c0b0b0" }}>|</div>
          <div
            onClick={() => handleCellClick("Large")}
            style={{
              cursor: "pointer",
              marginRight: "5px",
              backgroundColor:
                templateData.font_size_px === 12 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 12 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Large
          </div>
          <div style={{ color: "#c0b0b0" }}>|</div>
          <div
            onClick={() => handleCellClick("Extra Large")}
            style={{
              cursor: "pointer",
              backgroundColor:
                templateData.font_size_px === 14 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 14 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Extra Large
          </div>
        </div>
      </div>

      <div
        className="d-flex align-items-center"
        style={{
          gap: "2px",
          color: templateData.print_header ? "gray" : "black",
          caretColor: "transparent",
        }}
      >
        <p style={{ marginRight: "196px" }}>Top Margin: </p>
        <ControlPointIcon
          style={{
            cursor: "pointer",
            caretColor: "transparent",
            color: templateData.print_header ? "gray" : "#1976d2",
          }}
          onClick={increaseTopMargin}
          value={templateData.top_margin_cm}
        />
        <p style={{ marginRight: "1px" }}>
          {templateData.top_margin_cm < 10
            ? "0" + templateData.top_margin_cm
            : templateData.top_margin_cm}
        </p>
        <RemoveCircleOutlineIcon
          style={{
            cursor: "pointer",
            caretColor: "transparent",
            color: templateData.print_header ? "gray" : "#1976d2",
          }}
          value={templateData.top_margin_cm}
          onClick={decreaseTopMargin}
        />
        cm
      </div>

      <div
        className="d-flex align-items-center"
        style={{
          caretColor: "transparent",
          gap: "2px",
          color: templateData.print_footer ? "gray" : "black",
        }}
      >
        <p style={{ marginRight: "170px" }}>Bottom Margin:</p>
        <ControlPointIcon
          style={{
            cursor: "pointer",
            color: templateData.print_footer ? "gray" : "#1976d2",
          }}
          value={templateData.bottom_margin_cm}
          onClick={increaseBottomMargin}
        />
        {/* <p>{templateData.bottom_margin_cm}</p> */}
        <p>
          {templateData.bottom_margin_cm < 10
            ? "0" + templateData.bottom_margin_cm
            : templateData.bottom_margin_cm}
        </p>
        <RemoveCircleOutlineIcon
          style={{
            cursor: "pointer",
            color: templateData.print_footer ? "gray" : "#1976d2",
          }}
          value={templateData.bottom_margin_cm}
          onClick={decreaseBottomMargin}
        />
        cm
      </div>
    </div>
  );
};

const QuadrantGrid = () => {
  const doctorContext = useContext(PatientpageContext);
  const overlay = useContext(OverlayContext);
  const Overlay = useContext(OverlayContext);
  const [prescriptionPreferences, setPrescriptionPreferences] = useState(null);
  const location = useLocation();
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const appointmentid = appointment?.appointmentItem?.appointment_id;
  const doctorid = sessionStorage.getItem("doctorid");
  const case_file_id = sessionStorage.getItem("case_file_id");
  const profileid = sessionStorage.getItem("profileid");
  const [sharePrescription, setSharePrescription] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [showLoader, setShowLoader] = useState(false); // State to control loader visibility
  const [selectedRecords, setSelectedRecords] = useState("");

  const setState = doctorContext.setState;

  const history = useHistory("");
  const [formdata, setFormdata] = useState({
    text: "",
    quadrant_one_list: "",
    quadrant_two_list: "",
    quadrant_three_list: "",
    quadrant_four_list: "",
    selectedIndex: null,
  });

  const [selectedTeeth, setSelectedTeeth] = useState({
    quadrant_one: [],
    quadrant_two: [],
    quadrant_three: [],
    quadrant_four: [],
  });
  const parseQuadrantInput = (input) => {
    return input
      .split(",") // Split by comma
      .map((item) => {
        const trimmedItem = item.trim();
        return isNaN(parseInt(trimmedItem)) ? trimmedItem : `"${trimmedItem}"`;
      })
      .filter((item) => item !== ""); // Remove empty items
  };

  const handleTeethSelect = (quadrant, tooth) => {
    setSelectedTeeth((prev) => {
      let updatedQuadrant = prev[quadrant].includes(tooth)
        ? prev[quadrant].filter((t) => t !== tooth)
        : [...prev[quadrant], tooth];

      return {
        ...prev,
        [quadrant]: updatedQuadrant.map((item) =>
          isNaN(parseInt(item)) ? item : item.replace(/"/g, "")
        ),
      };
    });

    const quadrantKey = `${quadrant}_list`;
    setFormdata((prev) => ({
      ...prev,
      [quadrantKey]: selectedTeeth[quadrant].join(", "),
    }));
  };

  const [dentalNotes, setDentalNotes] = useState([]);
  const [sel, setSel] = useState([]);
  const [dentdata, setDentdata] = useState([]);
  const [diseases, setDiseases] = useState([]);
  const [selectedDisease, setSelectedDisease] = useState("");
  const [disease, setDisease] = useState([]);
  const [bub, setBub] = useState("");
  const [bookletChoices, setBookletChoices] = useState(false);
  const reference = useRef();
  reference.current = bookletChoices;

  const Loaders = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  const fetchDentalData = async () => {
    // setShowLoader(true)
    try {
      const response = await ServiceCall.getv2(
        "v2/doctor/manage/dental/case/note/",
        `${appointmentid}/${case_file_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      console.log("Full response:", response?.data?.dental_notes);
      setSel(response?.data?.dental_notes?.dental_notes);
      setDentdata(response?.data);
      if (response.status === 200) {
        // setShowLoader(false)

        // Check the response data structure
        if (Array.isArray(response.data) && response.data.length > 0) {
          setDentalNotes(response.data);
        } else {
          console.error("Unexpected dental data format:", response.data);
          setDentalNotes([]);
        }
      } else {
        console.error("Error fetching dental data:", response);
        setDentalNotes([]);
      }
    } catch (err) {
      console.error("Error fetching dental data:", err);
      setDentalNotes([]);
    }
  };

  useEffect(() => {
    fetchDentalData();
  }, []);

  useEffect(() => {
    const fetchdiseases = async () => {
      try {
        const fetchdisea = await ServiceCall.getnewv3(
          "/v2/doctor/list/diseases",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (fetchdisea) {
          setDiseases(fetchdisea?.data?.records || []);
        }
      } catch (err) {}
    };
    fetchdiseases();
  }, []);

  useEffect(() => {
    fetchdiseasess(selectedDisease);
  }, [selectedDisease]);

  const handleDiseaseChange = async (event) => {
    // setShowLoader(true);
    const selectedDiseaseId = event.target.value;

    try {
      // Make the API call immediately with the selected disease ID
      const res = await ServiceCall.putv2(
        "/v2/doctor/patient/profile/upsert/disease/",
        `${profileid}/${selectedDiseaseId}`,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (res) {
        // setShowLoader(false);
        setState({
          msg: res.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });

        // Update the state and fetch diseases after the API call is successful
        setSelectedDisease(selectedDiseaseId);
        await fetchdiseasess();
      }
    } catch (err) {
      console.error("Error updating disease:", err);
      // Handle error state if needed
    }
  };

  const handleDiseaseChangess = async (digease_id) => {
    try {
      // Assuming you have a PUT endpoint to update the selected disease
      const res = await ServiceCall.putv2(
        "/v2/doctor/patient/profile/upsert/disease/",
        `${profileid}/${digease_id}`,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (res) {
        // await fetchdiseasess();
        setState({
          msg: res.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
        await fetchdiseasess();
      }
    } catch (err) {
      console.error("Error updating disease:", err);
    }
  };

  const fetchdiseasess = async () => {
    // setShowLoader(true);
    try {
      const fetchdiseass = await ServiceCall.getv2(
        "/v2/doctor/list/patient/profile/diseases/",
        `${profileid}`,

        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (fetchdiseass) {
        // setShowLoader(false);
        setDisease(fetchdiseass?.data?.records || []);
      }
    } catch (err) {}
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormdata((prevState) => ({
      ...prevState,
      text: "",
    }));
    setSelectedTeeth({
      quadrant_one: [],
      quadrant_two: [],
      quadrant_three: [],
      quadrant_four: [],
    });
  };

  const DentalChart = async () => {
    setLoading(true);
    try {
      const body = {
        text: formdata.text || "",
        upper_right_list: selectedTeeth.quadrant_one.map((item) =>
          item.replace(/"/g, "")
        ),
        upper_left_list: selectedTeeth.quadrant_two.map((item) =>
          item.replace(/"/g, "")
        ),
        bottom_right_list: selectedTeeth.quadrant_three.map((item) =>
          item.replace(/"/g, "")
        ),
        bottom_left_list: selectedTeeth.quadrant_four.map((item) =>
          item.replace(/"/g, "")
        ),
      };

      const DentData = await ServiceCall.putv2(
        "v2/doctor/manage/dental/case/note/",
        `${appointmentid}/${case_file_id}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (DentData) {
        setLoading(false);
        await fetchDentalData();
        await fetchbubble();
        resetForm();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const DoctorsAppointmentList = ({ appointmentDate, classes }) => {
    return (
      <DoctorsAppointmentListItemNew
        index={0}
        tabRoute="prescription"
        key={appointment?.appointmentItem?.appointment_id}
        appointmentItem={
          doctorContext?.patientInfo || appointment?.appointmentItem
        }
        classes={classes}
      />
    );
  };

  const onDeleteTreatment = async ({ treatmentIndex }) => {
    try {
      const data = await ServiceCall.putv2(
        "/v2/doctor/remove/dental/case/note/",
        `${appointmentid}/${case_file_id}/${treatmentIndex}`,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (data) {
        fetchDentalData();
      }
    } catch (err) {}
  };

  const fetchbubble = async () => {
    try {
      const bubble = await ServiceCall.gett(
        `v2/doctor/prescription/list/recent/dental/treatments`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (bubble && bubble.data && bubble.data.records) {
        setBub(bubble.data.records);
      }
    } catch (err) {
      console.error("Error fetching recent medications:", err);
    }
  };

  useEffect(() => {
    fetchbubble();
  }, []);

  const handleRecentMedication = (item) => {
    setFormdata((prevState) => ({
      ...prevState,
      text: item, // Set the text to the selected medication
    }));
  };

  // const handlerecent = (item) => {
  //   console.log(383893, item);
  //   setFormdata({
  //     text: item?.text,
  //   });
  // };

  const DentalNotes = ({ dentalNotes }) => {
    if (!Array.isArray(dentalNotes) || dentalNotes.length === 0) {
      return <div></div>;
    }

    return (
      <div className="ditos" style={{ marginLeft: "-13rem" }}>
        {dentalNotes.map((note, noteIndex) => (
          <div key={noteIndex}>
            <h3 style={{ textAlign: "center", marginLeft: "-3rem" }}>
              {note.doc_name} ({note?.date_time})
            </h3>
            {note.treatments.map((treatment, treatmentIndex) => (
              <div key={treatmentIndex} className="treatments">
                <div
                  className="treatments-text"
                  // onClick={() => handlerecent(treatment)}
                >
                  {treatment.text}
                  {note.doc_id === doctorid &&
                    note.appointment_id === appointmentid && (
                      <RemoveCircleOutlineIcon
                        className="remove-icon"
                        onClick={() =>
                          onDeleteTreatment({ noteId: note.id, treatmentIndex })
                        }
                        style={{ marginLeft: "1rem" }}
                      />
                    )}
                </div>
                <div
                  className="quadrants-grid"
                  style={{ marginLeft: "-35rem" }}
                >
                  {[
                    { name: "upper_right", label: "Upper Right" },
                    { name: "upper_left", label: "Upper Left" },
                    { name: "bottom_right", label: "Bottom Right" },
                    { name: "bottom_left", label: "Bottom Left" },
                  ].map((quadrant, index) => (
                    <QuadrantComponents
                      key={index}
                      quadrantNumber={index + 1}
                      selectedTeeth={treatment[quadrant.name]}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const QuadrantComponents = ({ quadrantNumber, selectedTeeth }) => {
    const teeth = Array.from({ length: 8 }, (_, i) => i + 1);
    const shouldReverse = quadrantNumber === 1 || quadrantNumber === 3;
    const alphabets = ["A", "B", "C", "D", "E"];

    const getQuadrantText = () => {
      if (!Array.isArray(selectedTeeth) || selectedTeeth.length === 0) {
        return "";
      }

      const selectedAlphabets = [];
      const selectedNumbers = [];

      selectedTeeth.forEach((item) => {
        if (typeof item === "string") {
          selectedNumbers.push(item.replace(/"/g, ""));
        } else {
          selectedAlphabets.push(item);
        }
      });

      const quadrantText = [
        ...(shouldReverse ? selectedAlphabets.reverse() : selectedAlphabets),
        ...(shouldReverse ? selectedNumbers.reverse() : selectedNumbers),
      ]
        .join(", ")
        .trim();

      return quadrantText;
    };

    const quadrantText = getQuadrantText();

    return (
      <div
        className={`quadrants quadrants-${quadrantNumber} ${
          quadrantText ? "" : "empty-quadrant"
        }`}
      >
        {quadrantText && <div className="quadrants-text">{quadrantText}</div>}
      </div>
    );
  };

  const QuadrantComponent = ({
    quadrantNumber,
    selectedTeeth,
    onTeethSelect,
  }) => {
    const teeth = Array.from({ length: 8 }, (_, i) => i + 1);
    const shouldReverse = quadrantNumber === 1 || quadrantNumber === 3;
    const alphabets = ["A", "B", "C", "D", "E"];

    const quadrantLabels = {
      1: "UR",
      2: "UL",
      3: "BR",
      4: "BL",
    };

    const renderTeethWithButton = (teethArray) => (
      <div className="teeth-container">
        {quadrantNumber === 1 || quadrantNumber === 3 ? (
          <>
            <button className="quadrant-label">
              {quadrantLabels[quadrantNumber]}
            </button>
            {teethArray.map((tooth) => (
              <div
                key={tooth}
                className={`tooth ${
                  selectedTeeth.includes(`"${tooth}"`) ? "selected" : ""
                }`}
                onClick={() => onTeethSelect(`"${tooth}"`)}
              >
                {tooth}
              </div>
            ))}
          </>
        ) : (
          <>
            {teethArray.map((tooth) => (
              <React.Fragment key={tooth}>
                <div
                  className={`tooth ${
                    selectedTeeth.includes(`"${tooth}"`) ? "selected" : ""
                  }`}
                  onClick={() => onTeethSelect(`"${tooth}"`)}
                >
                  {tooth}
                </div>
                {tooth === 8 && (
                  <button className="quadrant-label">
                    {quadrantLabels[quadrantNumber]}
                  </button>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    );

    return (
      <div className={`quadrant quadrant-${quadrantNumber}`}>
        {quadrantNumber === 1 || quadrantNumber === 2 ? (
          <>
            <div className="teeth-container">
              {(shouldReverse ? alphabets.reverse() : alphabets).map(
                (alpha) => (
                  <div
                    key={alpha}
                    className={`tooth ${
                      selectedTeeth.includes(alpha) ? "selected" : ""
                    }`}
                    onClick={() => onTeethSelect(alpha)}
                  >
                    {alpha}
                  </div>
                )
              )}
            </div>
            {renderTeethWithButton(shouldReverse ? teeth.reverse() : teeth)}
          </>
        ) : (
          <>
            {renderTeethWithButton(shouldReverse ? teeth.reverse() : teeth)}
            <div className="teeth-container">
              {(shouldReverse ? alphabets.reverse() : alphabets).map(
                (alpha) => (
                  <div
                    key={alpha}
                    className={`tooth ${
                      selectedTeeth.includes(alpha) ? "selected" : ""
                    }`}
                    onClick={() => onTeethSelect(alpha)}
                  >
                    {alpha}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const Loader = (text) => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <div className="prescripitionLoader">
          <h3>Please Wait...</h3>
          <p>{text}</p>
          <CircularProgress color="success" />
        </div>
      ),
      removeCloseButton: true,
    });
  };

  const handlePrescriptionDownload = async () => {
    // setShowLoader(true)
    let bookletChoice = null;

    try {
      bookletChoice = await new Promise((resolve, reject) => {
        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div style={{
              position: "fixed",
              inset: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <div style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
                padding: "1.5rem",
                maxWidth: "25rem",
                width: "100%",
                marginTop: "9rem",
              }}>
                <h2 style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  textAlign: "center",
                  color: "#1f2937",
                  marginTop: "-1rem",
                }}>
                  Print Options
                </h2>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}>
                  <button
                    onClick={() => resolve(true)}
                    style={{
                      backgroundColor: "rgb(16 149 137)",
                      color: "white",
                      fontWeight: "600",
                      padding: "0.5rem 1rem",
                      borderRadius: "0.25rem",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = "rgb(15 112 103)"}
                    onMouseOut={(e) => e.target.style.backgroundColor = "rgb(16 149 137)"}
                  >
                    With Booklet
                  </button>
                  <button
                    onClick={() => resolve(false)}
                    style={{
                      backgroundColor: "#d1d5db",
                      color: "#1f2937",
                      fontWeight: "600",
                      padding: "0.5rem 1rem",
                      borderRadius: "0.25rem",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = "#9ca3af"}
                    onMouseOut={(e) => e.target.style.backgroundColor = "#d1d5db"}
                  >
                    Without Booklet
                  </button>
                </div>
              </div>
            </div>
          ),
          handleClose: () => reject(new Error('User closed the dialog')),
        });
      });
    } catch (error) {
      // User closed the dialog, so we just return from the function
      Overlay.hide();
      return;
    }
  
    setBookletChoices(bookletChoice);

    const body = {
      bottom_margin_cm: prescriptionPreferences?.bottom_margin_cm ?? 0,
      cf_chart_or_table: false,
      font_size_px: prescriptionPreferences?.font_size_px ?? 12,
      left_margin_cm: prescriptionPreferences?.left_margin_cm ?? 0,
      print_footer: prescriptionPreferences?.print_footer ?? true,
      print_header: prescriptionPreferences?.print_header ?? true,
      print_on_both_sides:
        prescriptionPreferences?.print_on_both_sides ?? false,
      print_template: prescriptionPreferences?.print_template ?? "",
      print_watermark: prescriptionPreferences?.print_watermark ?? false,
      right_margin_cm: prescriptionPreferences?.right_margin_cm ?? 0,
      secondary_language_code: "en",
      top_margin_cm: prescriptionPreferences?.top_margin_cm ?? 0,
      validity: 7,
      print_booklet: bookletChoice
    };

    let url = "/v2/doctor/fetch/dental/prescription/file/";
    Loader("");

    try {
      const PrescriptionGenerate = await ServiceCall.putv2(
        "v2/doctor/gen/dental/prescription/file/",
        `${appointmentid}/${case_file_id}`,
        body,
        {
          "Content-Type": "application/json",
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (PrescriptionGenerate) {
        // setShowLoader(false)

        try {
          const PrescriptionDownload = await apiCall({
            url,
            method: "get",
            responseType: "blob",
            hosID: appointmentid,
            extraField: `/${case_file_id}`,
            headers: {
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            },
          });
          if (PrescriptionDownload) {
            Overlay.hide();
            let blob = new Blob([PrescriptionDownload.data], {
              type: "application/pdf",
            });
            let url = window.URL.createObjectURL(blob);
            Overlay.show(OverlayType?.ModalWindow, {
              data: (
                <div className="prescription__pdf">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={url} />
                  </Worker>
                  <div className="prescription__pdf__container">
                    <a
                      href={url}
                      target="_blank"
                      className="prescription__pdf__button"
                    >
                      <LocalPrintshopIcon />
                    </a>
                    <a
                      download={`prescription-${appointmentid}`}
                      href={url}
                      className="prescription__pdf__button"
                    >
                      <DownloadForOfflineIcon />
                    </a>
                    <div
                      className="prescription__pdf__button"
                      onClick={handleSharePrescription}
                      style={{ fontSize: "1.4rem" }}
                    >
                      <SendIcon
                        sx={{ fontSize: "20px", marginLeft: ".4rem" }}
                      />
                    </div>
                  </div>
                </div>
              ),
              handleClose: () => {},
            });
            setSharePrescription(true);
          } else {
            return PrescriptionDownload.text();
          }
        } catch (err) {
          Overlay.hide();
          setState({
            msg: err?.message || "",
            type: "error",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const printsub = async () => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <GeneratePrescriptionModal
          onSave={(data) => {
            setPrescriptionPreferences(data);
          }}
        />
      ),
      handleClose: () => {},
    });
  };

  const handleSharePrescription = async () => {
    Loader("Sharing Prescription");
    try {
      const body = {
        print_booklet: reference.current,
      };
      const sharePrescription = await ServiceCall.postv2(
        "/v2/doctor/dental/prescription/submission/",
        `${appointmentid}/${case_file_id}`,
        body,

        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (sharePrescription) {
        // setShowLoader(false)

        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescripitionLoader">
              <img src={PrescriptionShareIcon} />
              <p>{sharePrescription?.data?.message}</p>
            </div>
          ),
          removeCloseButton: false,
          handleClose: function close() {
            history.push({
              pathname: "/dental-prescriptions",
            });
          },
        });
      } else {
        Overlay.hide();
        setState({
          msg: "Failed to share prescription",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "An error occurred",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleback = () => {
    history.push({
      pathname: "/my-appointments",
    });
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <>
      {showLoader ? (
        <Loaders />
      ) : (
        <PageContentLayout>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              fontSize: "22px",
              cursor: "pointer",
            }}
            onClick={handleback}
          >
            <ArrowBackIosNewIcon
              style={{ marginTop: "-0.5rem", marginBottom: "-0.5rem" }}
            />
            Dental Prescription
          </h1>
          <div className=""></div>
          <DoctorsAppointmentList
            appointmentDate={
              appointment?.appointmentItem?.appointment_date_formatted
            }
            classes={appointment?.classes}
          />
          <div
            className="appointment__container"
            style={{ background: "#e8f3f1", marginTop: "19px" }}
          >
            <Grid
              item
              container
              xs={10}
              sm={12}
              className="appointment__container__lastsection prescription__container__lastsection"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  width: "100%",
                  padding: "19px 20px",
                  gap: "62px",
                }}
              >
                <div
                  className="appointment__date__record"
                  onClick={() => {
                    localStorage.setItem(
                      "previousPrescriptionRoute",
                      location.pathname
                    );
                    history.push({
                      pathname: "/prescription/patient_notes",
                      search: history?.location?.search,
                    });
                  }}
                  style={{ display: "flex", alignItems: "center", flex: 1 }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Patient Notes{" "}
                    <MedicationRoundedIcon sx={{ fontSize: "25px" }} />
                  </span>
                </div>
                <div
                  className="appointment__date__record"
                  onClick={() => {
                    history.push("/vital", {
                      sourceRoute: "dental-prescriptions",
                    });
                  }}
                  style={{ display: "flex", alignItems: "center", flex: 1 }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Patient Vitals{" "}
                    <img
                      src={Vitalimg}
                      style={{
                        fontSize: "25px",
                        height: "26px",
                        width: "41px",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                  </span>
                </div>
                <div
                  className="appointment__date__record"
                  style={{ display: "flex", alignItems: "center", flex: 1 }}
                  onClick={printsub}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Print Prefernces{" "}
                    <LocalPrintshopIcon sx={{ fontSize: "25px" }} />
                  </span>
                </div>
                <div
                  className="appointment__date__record"
                  style={{ display: "flex", alignItems: "center", flex: 1 }}
                >
                  <span
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={handlePrescriptionDownload}
                  >
                    View/Generate{" "}
                    <VisibilityIcon
                      sx={{ fontSize: "25px", marginLeft: ".4rem" }}
                    />
                  </span>
                </div>
              </div>
            </Grid>
          </div>

          <div
            style={{
              margin: 0,
              padding: "0.25rem 0.5rem",
              backgroundColor: "#f5f5f5",
              borderRadius: "4px",
              marginTop: "1rem",

              display: "flex",
            }}
          >
            <Box sx={{ minWidth: 60 }}>
              <InputLabel
                id="disease-select-label"
                style={{ fontSize: ".9rem", fontWeight: "900" }}
              >
                Select to add any existing medical conditions for patient from
                drop down:
              </InputLabel>
              <FormControl style={{ width: "50%" }}>
                <Select
                  labelId="disease-select-label"
                  id="disease-select"
                  value={selectedDisease}
                  onChange={handleDiseaseChange}
                  label="Select Disease"
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>Select Diseases</em>
                  </MenuItem>
                  {diseases.map((disease) => (
                    <MenuItem
                      key={disease.disease_id}
                      value={disease.disease_id}
                    >
                      {disease.disease_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  marginTop: "1.2rem",
                }}
              >
                {disease.map((diseas, index) => (
                  <Chip
                    key={diseas.disease_id}
                    label={` ${diseas.disease_name}`}
                    onDelete={() => handleDiseaseChangess(diseas.disease_id)}
                    deleteIcon={<RemoveCircleOutlineIcon />}
                    sx={{
                      backgroundColor: "#e0e0e0",
                      borderRadius: "16px",
                      "&:hover": {
                        backgroundColor: "#d0d0d0",
                      },
                      marginBottom: ".5rem",
                    }}
                  />
                ))}
              </Box>
            </Box>
          </div>

          {(dentdata?.case_id || dentdata?.reported_problem) && (
            <div
              className="case-info"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "1rem",
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                margin: "1rem 0",
              }}
            >
              {dentdata?.case_id && (
                <div
                  className="case-id"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "0.5rem",
                    }}
                  >
                    Case ID:
                  </span>
                  <p
                    style={{
                      margin: 0,
                      padding: "0.25rem 0.5rem",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "4px",
                    }}
                  >
                    {dentdata.case_id}
                  </p>
                </div>
              )}
              {dentdata?.reported_problem && (
                <div
                  className="reported-problem"
                  style={{
                    flexGrow: 1,
                    marginLeft: dentdata?.case_id ? "2rem" : "0",
                    maxWidth: dentdata?.case_id ? "60%" : "100%",
                  }}
                >
                  <TextField
                    multiline
                    maxRows={5}
                    value={dentdata.reported_problem}
                    label="Reported Problem:"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <DentalNotes dentalNotes={sel} />

          <div className="dito" style={{ background: "#fff" }}>
            <p style={{ fontWeight: "bold", marginLeft: "1rem" }}>
              Dental Chart (Click to select)
            </p>

            <div className="quadrant-grid" style={{ marginLeft: "-16rem" }}>
              {["one", "two", "three", "four"].map((quadrant, index) => (
                <QuadrantComponent
                  key={index}
                  quadrantNumber={index + 1}
                  selectedTeeth={selectedTeeth[`quadrant_${quadrant}`]}
                  onTeethSelect={(tooth) =>
                    handleTeethSelect(`quadrant_${quadrant}`, tooth)
                  }
                />
              ))}
            </div>
          </div>
          <div className="dito">
            <p style={{ fontWeight: "bold", marginLeft: "1rem" }}>
              Click to add note
            </p>
            <div
              className="prescription_recent-medication"
              style={{ marginBottom: "-1rem" }}
            >
              <div className="prescription_recent-medication__scroll">
                {Array.isArray(bub) ? (
                  bub.map((item, index) => (
                    <div
                      key={index}
                      className={`prescription_recent-medication_button ${
                        formdata.text === item
                          ? "prescription_recent-medication_button_selected"
                          : ""
                      }`}
                      onClick={() => handleRecentMedication(item)}
                    >
                      {truncateText(item, 3)}
                    </div>
                  ))
                ) : (
                  <p>No recent medications available</p>
                )}
              </div>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginTop: "1rem",
                alignItems: "center",
              }}
            >
              <TextField
                multiline
                maxRows={5}
                name="text"
                id="outlined-basic"
                label="Add Note"
                variant="outlined"
                value={formdata.text}
                onChange={handleChange}
                style={{
                  marginTop: "0rem",
                  width: "70%",
                  textAlign: "center",
                  background: "#fff",
                }}
              />
              <Button
                variant="contained"
                style={{
                  background: "#199a8e",
                  color: "#fff",
                  marginTop: "1rem",
                  width: "30%",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
                disabled={loading}
                onClick={DentalChart}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "SAVE"
                )}
              </Button>
            </div>
          </div>
        </PageContentLayout>
      )}
    </>
  );
};

export default QuadrantGrid;
